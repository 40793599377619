import Vue from 'vue'
import 'babel-polyfill'
// Use the vuetify loader (default in vue cli), so that we can overwrite sass variables (src/styles/variables.scss): https://vuetifyjs.com/en/customization/sass-variables/#vue-cli-install
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'
import Vuetify from 'vuetify/lib'

// import own i18n
import de from 'vuetify/es5/locale/de'
import fr from 'vuetify/es5/locale/fr'
// import custom page i18n
import * as pageDe from './i18n/de.json';
Object.assign(de, pageDe);
import * as pageFr from './i18n/fr.json';
Object.assign(fr, pageFr);

// Automatically register all components and directives to that they can be used.
Vue.use(Vuetify, {
    components: components,
    directives: directives
});

let init = function (obj) {
    obj = obj || {};
    obj.lang = {
        locales: { de, fr },
        current: 'de',
    };
    obj.theme = {
        themes: {
            light: {
                primary: '#ef7c00',
                secondary: '#bcbec2',
                error: '#DA322B'
            }
        }
    };
    obj.icons = {
        iconfont: 'mdi'
    };

    return new Vuetify(obj);
};

export default init;